import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import { Banner } from '../acf/Banner';
import fourOhFourSvg from '../images/404.svg';
import SEO from '../components/SEO';
import './404.scss';

const NotFoundPage = () => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            siteTitle: title
            wordpressUrl
          }
        }
      }
    `}
    render={(data) => {
      return (
        <Layout className="fourohfour-page-template">
          <SEO title={`404 | ${data.site.siteMetadata.siteTitle}`} />
          <Banner
            image={null}
            title="404"
            wordpressUrl={data.site.siteMetadata.wordpressUrl}
            hideHeading
          />
          <section className="four-oh-four-section">
            <div className="wrapper">
              <img src={fourOhFourSvg} className="four-oh-four-image" alt="404 SVG" />
              <h1>
                404
                <span>Page not found</span>
              </h1>
            </div>
          </section>
        </Layout>
      );
    }}
  />
)

export default NotFoundPage
